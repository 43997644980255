<template>
  <div id="dashboard-reservations">
    <b-row class="mb-3">
      <b-col md="5" lg="3">
        <z-search-input
          v-model="searchText"
          placeholder="Buscar por ID orden"
        />
      </b-col>
      <b-col v-if="isCompany" lg="2" class="mt-3 mt-lg-0">
        <z-dropdown-clients id="dropdown-clients" v-model="filterClient" />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-date"
          v-model="filterStatus"
          :options="statusOptions"
          name="Estado"
        />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-date"
          v-model="filterVehicleType"
          :options="vehicleTypeOptions"
          name="Vehículo"
        />
      </b-col>
      <b-col class="mt-3 mt-lg-0 ">
        <div class="d-flex flex-row-reverse">
          <z-button-refresh
            :loading="loading"
            :has-updates="hasUpdates"
            @refresh="getListingAndResetPagination"
          />
        </div>
      </b-col>
    </b-row>
    <quick-message
      :title="quickMessage.title"
      :message="quickMessage.message"
      :show.sync="showQuickMessage"
      :type="quickMessage.type"
      with-icon
      class="my-2"
    />
    <z-table
      :items="reservations"
      :fields="fields"
      :loading="loading"
      empty-message="Sin reservaciones para hoy"
      @row-clicked="viewServiceDetail"
    >
      <!-- Headers -->
      <template v-slot:head(status)>
        <div>
          <div class="d-lg-none">
            Estatus
          </div>
          <z-table-header-dropdown
            id="dropdown-status"
            v-model="filterStatus"
            class="d-none d-lg-block"
            :options="statusOptions"
            name="Estatus"
          />
        </div>
      </template>

      <template v-slot:head(start)>
        <div>
          <div class="d-lg-none">
            Check in
          </div>
          <z-table-header-dropdown
            id="dropdown-checkin"
            v-model="filterDate"
            class="d-none d-lg-block"
            :options="dateOptions"
            name="Check in"
          />
        </div>
      </template>

      <template v-slot:head(vehicleType)>
        <div>
          <div class="d-lg-none">
            Vehículo
          </div>
          <z-table-header-dropdown
            id="dropdown-vehicle-type"
            v-model="filterVehicleType"
            class="d-none d-lg-block"
            button-class="text-center"
            :options="vehicleTypeOptions"
          />
        </div>
      </template>

      <!-- Cells -->
      <template v-slot:cell(status)="row">
        <div>
          <status-service-reservation
            :status="row.item.status"
            :driver-id="row.item.driverId"
            :start="row.item.start"
          />
        </div>
      </template>

      <template v-slot:cell(type)="row">
        <service-type-badge :type="row.item.type" />
      </template>

      <template v-slot:cell(originAddress)="row">
        <z-shorten-text
          :text="row.item.originAddress"
          copy
          show-copy-on-hover
          :style="{ maxWidth: '200px' }"
        />
      </template>

      <template v-slot:cell(vehicleType)="row">
        <z-vehicle :type="row.item.vehicleType" />
      </template>

      <template v-slot:cell(actions)="row">
        <z-actions-menu>
          <template v-slot:button-content>
            <z-icon name="More" />
          </template>
          <b-dropdown-item @click="handleCancel(row.item)">
            Cancelar reservación
          </b-dropdown-item>
        </z-actions-menu>
      </template>
    </z-table>
    <router-link :to="{ name: 'reservations' }">
      Ver todas mis reservaciones
    </router-link>
    <z-table-pagination
      class="mt-2"
      :total-rows="totalReservations"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import {
  dateRange,
  diffInHours,
  formatISO
} from "@zubut/common/src/utils/time";
import ServiceType from "@zubut/common/src/constants/services/type";
import ZVehicle from "@zubut/common/src/components/ZVehicle";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh";
import DateConstants from "@/constants/filters/date";
import ServiceStatus from "@/constants/services/status";
import ReservationStatus from "@/constants/dashboard/status-reservation";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";
import filtering from "@/mixins/filtering";
import * as mutation from "@/store/modules/dashboard/reservations/mutations-types.js";
import _debounce from "lodash/debounce";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge";
import ZShortenText from "@zubut/common/src/components/ZShortenText";
import StatusServiceReservation from "@/app/components/StatusServiceReservation";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import DriverVehicles from "@/constants/drivers/vehicles";
import ZActionsMenu from "@zubut/common/src/components/ZActionsMenu";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import ZDropdownClients from "@/app/components/ZDropdownClients";

const moduleName = "dashboard/reservations";

export default {
  name: "DashboardReservations",

  components: {
    ServiceTypeBadge,
    StatusServiceReservation,
    ZActionsMenu,
    ZDropdown,
    ZDropdownClients,
    ZSearchInput,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination,
    ZShortenText,
    ZVehicle,
    ZButtonRefresh
  },

  mixins: [filtering("dashboard")],

  data() {
    return {
      serviceType: ServiceType,
      loading: false,
      showConfirmationModal: false,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      selectedService: null,
      fields: [
        { key: "status", label: "Estatus", class: "text-left" },
        { key: "type", label: "Entrega", class: "text-left" },
        {
          key: "id",
          label: "ID Orden",
          formatter: value => makeShortUUID(value),
          class: "id-column text-left"
        },
        {
          key: "start",
          label: "Check In",
          formatter: val =>
            val ? formatISO(val, "dd 'de' MMM yyyy p aaa") : "",
          class: "check-in-column  text-left"
        },
        { key: "origin", label: "Origen", class: "text-left" },
        {
          key: "originAddress",
          label: "Dirección",
          class: "address-column text-left"
        },
        {
          key: "driver",
          label: "Mensajero",
          class: "driver-column text-left"
        },
        {
          key: "vehicleType",
          label: "",
          class: "text-center"
        },
        {
          key: "actions",
          label: "Acciones",
          class: "text-right"
        }
      ],
      dateOptions: [
        { text: "Todos", value: null },
        { value: DateConstants.NUM_TODAY, text: DateConstants.TODAY },
        { value: DateConstants.NUM_TOMORROW, text: DateConstants.TOMORROW },
        { value: DateConstants.NUM_THIS_WEEK, text: DateConstants.THIS_WEEK }
      ],
      statusOptions: [{ text: "Todos", value: null }].concat(
        ReservationStatus.options
      ),
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        DriverVehicles.options
      ),
      showQuickMessage: false,
      quickMessage: {
        title: "Error",
        message: "",
        type: "error"
      }
    };
  },

  computed: {
    isCompany() {
      return this.$store.getters["user/isCompany"];
    },
    reservations() {
      return this.$store.getters[`${moduleName}/getReservations`];
    },
    stateFilters() {
      return this.$store.getters[`${moduleName}/getReservationsFilters`];
    },
    totalReservations() {
      return this.$store.getters[`${moduleName}/getReservationsTotal`];
    },
    selectedServiceId() {
      if (this.selectedService && this.selectedService.id) {
        return makeShortUUID(this.selectedService.id);
      }
      return "";
    },
    searchText: {
      get() {
        return this.stateFilters.search;
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_SEARCH}`, val);
      }
    },
    filterClient: {
      get() {
        return this.stateFilters.clientId;
      },
      set(val) {
        this.setFilter("client", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_CLIENT}`, val);
      }
    },
    filterDate: {
      get() {
        return this.stateFilters.date;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_DATE}`,
          val
        );
      }
    },
    filterStatus: {
      get() {
        return this.stateFilters.status;
      },
      set(val) {
        this.setFilter("status", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_STATUS}`,
          val
        );
      }
    },
    filterVehicleType: {
      get() {
        return this.stateFilters.vehicleType;
      },
      set(val) {
        this.setFilter("vehicleType", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_VEHICLE_TYPE}`,
          val
        );
      }
    },
    hasUpdates: {
      get() {
        return this.$store.getters[`${moduleName}/getHasUpdates`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.TOGGLE_UPDATE_INDICATOR}`,
          val
        );
      }
    }
  },

  watch: {
    filterDate: "getListingAndResetPagination",
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    }
  },

  created() {
    this.setRouteFilters();
    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getReservations(), 100);
    this.searchFilterListener = _debounce(() => {
      this.getReservations();
    }, 600);
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab || "dedicados";
      const page = this.currentPage;
      const show = this.perPage;
      const search = this.searchText;
      const status = this.filterStatus;
      const vehicleType = this.filterVehicleType;

      query.tab = query.tab ? query.tab : tab;
      query.search = query.search ? query.search : search;
      query.status = query.status || !status ? query.status : status;
      query.vehicleType = query.vehicleType
        ? query.vehicleType
        : vehicleType || query.vehicleType;
      query.page = query.page ? query.page : page;
      query.show = query.show ? query.show : show;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});

      this.searchText = query.search ?? "";
      this.filterClient = query.client ?? null;
      this.filterStatus = query.status ?? null;
      this.filterVehicleType = query.vehicleType ?? null;
    },

    getListingAndResetPagination() {
      this.hasUpdates = false;
      this.paginationReset();
      this.getReservations();
    },

    totalHours(start, end) {
      const diff = diffInHours(start, end);
      return `${diff} hrs`;
    },

    buildRequest() {
      const where = { like: this.searchText };

      where.status = [ServiceStatus.NUM_CREATED, ServiceStatus.NUM_ACTIVE];

      /* Set client filter */
      if (this.filterClient) {
        where.clientId = this.filterClient;
      }

      /* Set vehicle type filter */
      if (this.filterVehicleType !== null) {
        where.vehicleType = this.filterVehicleType;
      }

      /* Set date filter */
      if (this.filterDate != null) {
        const range = dateRange(this.filterDate);
        where.range = range;
      }

      /* Set status type filter */
      if (this.filterStatus !== null) {
        switch (this.filterStatus) {
          case ReservationStatus.NUM_IN_ROUTE:
            where.status = [ServiceStatus.NUM_ACTIVE];
            break;
          case ReservationStatus.NUM_WITHOUT_DRIVER:
            where.driversId = { eq: null };
            break;
          case ReservationStatus.NUM_SCHEDULED:
            this.filterDate = null;
            where.status = [ServiceStatus.NUM_CREATED];
            where.driversId = { neq: null };
            where.range = { from: new Date() };
            break;
          case ReservationStatus.NUM_LATE:
            this.filterDate = null;
            where.status = [ServiceStatus.NUM_CREATED];
            where.driversId = { neq: null };
            where.range = { until: new Date() };
            break;
        }
      }

      return { filter: this.pagination, where };
    },

    getReservations() {
      const { filter, where } = this.buildRequest();
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/getReservations`, { filter, where })
        .finally(() => {
          this.loading = false;
        });
    },

    dispatchCancel() {
      this.hideModal();
      this.loading = true;
      const service = this.selectedService;
      const cancel = {
        id: service.id,
        clientId: service.clientId,
        reason: 0
      };
      this.$store
        .dispatch(`${moduleName}/cancelService`, cancel)
        .then(res => {
          this.isSuccessful = true;
          this.message =
            res?.response?.message || "Servicio cancelado correctamente";
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message = err?.message || "Error al cancelar el servicio";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dispatchComplete(service) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/completeService`, service.id)
        .then(res => {
          this.message = res?.data?.message || "Servicio finalizado con exito";
          this.isSuccessful = true;
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.message =
            err?.message ||
            "Occurrió un error al intentar finalizar el servicio";
          this.hasError = true;
          this.typeOfMessage = "error";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showCancelServiceModal(service) {
      this.selectedService = service;
      this.showConfirmationModal = true;
    },

    hideModal() {
      this.showConfirmationModal = false;
    },

    paginationReset() {
      this.currentPage = 1;
    },

    viewServiceDetail(service) {
      this.$router.push({
        name: "reservationDetail",
        params: { id: service.id, driverId: service.driverId }
      });
    },

    handleAssignation(res) {
      this.message = res?.data?.message || "Servicio se asignó con éxito";
      this.isSuccessful = true;
      this.typeOfMessage = "success";
    },

    handleError(err) {
      this.message =
        err?.message || "Ocurrió un error al intentar finalizar el servicio";
      this.hasError = true;
      this.typeOfMessage = "error";
    },

    hasAdditionalHours(service) {
      return (
        service.additionalHours &&
        service.additionalHoursStatus === AdditionalHours.NUM_PENDING_HOURS
      );
    },

    handleCancel(item) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/cancel`, item)
        .then(res => {
          this.quickMessage = {
            title: "Éxito",
            type: "success",
            message: res.message || "Reservación cancelada existosamente"
          };
          this.getListingAndResetPagination();
        })
        .catch(err => {
          this.loading = false;
          this.quickMessage = {
            title: "Error",
            type: "error",
            message:
              err.message ||
              "Ocurrió un error al intenter cancelar la reservación"
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#dashboard-reservations {
  ::v-deep .id-column {
    min-width: 100px;
  }

  ::v-deep .check-in-column {
    min-width: 158px;
  }

  ::v-deep .address-column {
    min-width: 170px;
  }

  ::v-deep .driver-column {
    min-width: 127px;
  }
}
</style>
