<template>
  <div
    class="service-selection d-md-flex justify-content-md-between align-items-md-center"
  >
    <div class="request d-flex align-items-center pl-2 mb-4 mb-md-0 mr-md-3">
      <z-icon name="AddAlt" class="mr-3" />
      <div class="text-lg">Solicitar</div>
    </div>
    <div class="d-md-flex">
      <div
        v-for="(service, index) in serviceTypes"
        :key="`service-type-${service.type}`"
      >
        <div v-if="service.enabled">
          <z-service-type
            :enabled="service.enabled"
            :type="service.type"
            data-test-id="z-service-type"
            class="service-type"
            :class="[
              'service-type mb-3 mb-md-0 ',
              { 'mr-md-3': index !== serviceTypes.length - 1 }
            ]"
            @click.native="handleSelection(service.type)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZServiceType from "@/app/components/ZServiceType";
import ZIcon from "@zubut/common/src/components/ZIcon";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "ServiceSelection",

  components: {
    ZIcon,
    ZServiceType
  },

  data() {
    return {
      ServiceType
    };
  },

  computed: {
    enabledServices() {
      return this.$store.getters["user/getEnabledServices"];
    },
    serviceTypes() {
      return [
        {
          type: ServiceType.NUM_MULTI_POINT,
          enabled:
            this.enabledServices.findIndex(type =>
              ServiceType.isMultiPoint(type)
            ) > -1
        },
        {
          type: ServiceType.NUM_PARCEL,
          enabled:
            this.enabledServices.findIndex(
              type => type === ServiceType.NUM_PARCEL
            ) > -1
        },
        {
          type: ServiceType.NUM_RENT_PER_HOUR,
          enabled:
            this.enabledServices.findIndex(
              type => type === ServiceType.NUM_RENT_PER_HOUR
            ) > -1
        }
      ];
    }
  },

  methods: {
    handleSelection(type) {
      const isServiceTypeEnabled = this.$store.getters[
        "user/isServiceTypeEnabled"
      ](type);

      if (isServiceTypeEnabled) {
        this.$emit("selected", type);
      }
    },
    showServices(service) {
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.service-selection {
  padding: 1.4rem;
  background-color: $white;
  border-radius: 1em;
  border: 1px solid $solitude;
}

.request {
  font-weight: bold;
}
</style>
