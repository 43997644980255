<template>
  <div id="dashboard-main-container">
    <selection-modal :show.sync="showModal" />
    <b-row class="mb-4">
      <b-col lg="8" xl="7" class="mb-3 mb-lg-0">
        <service-selection
          class="service-selection w-100 h-100"
          @selected="handleServiceSelection"
        />
      </b-col>
      <b-col lg="4" xl="5">
        <wallet-balance />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="table-wrapper">
          <div
            class="tracking-title-wrapper d-flex align-items-center text-xl font-weight-bold pb-4"
          >
            <z-icon name="Dashboard" size="20" />
            <div class="tracking-title ml-4">
              Rastreo de entregas
            </div>
          </div>
          <z-tabs
            class="z-tabs-dashboard"
            content-class="mt-3"
            align="end"
            pills
            responsive
            lazy
          >
            <template #default>
              <z-tab
                :active="!$route.query.tab || $route.query.tab === 'entregas'"
                @click="changeTab('entregas')"
              >
                <template #title>
                  <div>
                    Entregas
                    <z-badge
                      pill
                      :variant="
                        !$route.query.tab || $route.query.tab === 'entregas'
                          ? 'white'
                          : 'secondary'
                      "
                      class="ml-2"
                    >
                      {{ totalDeliveries }}
                    </z-badge>
                  </div>
                </template>
                <dashboard-deliveries />
              </z-tab>
              <z-tab
                :active="$route.query.tab === 'reservaciones'"
                @click="changeTab('reservaciones')"
              >
                <template #title>
                  <div>
                    Dedicados
                    <z-badge
                      pill
                      :variant="
                        $route.query.tab === 'reservaciones'
                          ? 'white'
                          : 'secondary'
                      "
                      class="ml-2"
                    >
                      {{ totalReservations }}
                    </z-badge>
                  </div>
                </template>

                <dashboard-reservations />
              </z-tab>
            </template>
          </z-tabs>
        </div>
      </b-col>
    </b-row>
    <router-view></router-view>
  </div>
</template>

<script>
import ServiceType from "@zubut/common/src/constants/services/type";
import BrowserNotification from "@zubut/common/src/models/browser-notification";
import WalletBalance from "@/app/components/WalletBalance";
import ServiceSelection from "./ServiceSelection";
import DashboardReservations from "./DashboardReservations";
import DashboardDeliveries from "./DashboardDeliveries";
import SelectionModal from "./SelectionModal/SelectionModal";

export default {
  name: "Dashboard",

  components: {
    DashboardReservations,
    DashboardDeliveries,
    ServiceSelection,
    WalletBalance,
    SelectionModal
  },
  data() {
    return { showModal: false };
  },

  computed: {
    totalDeliveries() {
      return this.$store.getters["dashboard/deliveries/getDeliveriesTotal"];
    },
    totalReservations() {
      return this.$store.getters["dashboard/reservations/getReservationsTotal"];
    }
  },

  methods: {
    changeTab(tab) {
      this.$router
        .replace({ name: "dashboard", query: { tab } })
        .catch(error => {
          this.$captureError(error);
        });
    },
    handleServiceSelection(type) {
      //  TODO: Delete this (Express is going to be deprecated)
      if (ServiceType.isMultiPoint(type)) {
        this.$router.push({ name: "createExpressDelivery" }).catch(() => {});
      }
      if (ServiceType.isParcel(type)) {
        this.showModal = true;
      }
      if (type === ServiceType.NUM_RENT_PER_HOUR)
        this.$router.push({ name: "rents" }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.service-selection {
  width: 50%;
}

.z-wallet {
  background-color: var(--primary);
  border-radius: 1em;
  width: 50%;
}

.table-wrapper {
  padding: 1.7rem;
  background-color: $white;
  border-radius: 1em;
  border: 1px solid $solitude;
}

.tracking-title {
  font-size: 1.2rem;
}

@include media-breakpoint-up(md) {
  .service-selection {
    grid-column: span 3;
  }

  .z-wallet {
    grid-column: span 3;
  }

  .tracking-title-wrapper {
    position: absolute;
    left: 3rem;
    top: 2rem;

    .tracking-title {
      font-size: 1.5rem;
    }
  }
}
</style>

<style lang="scss">
.z-tabs.z-tabs-dashboard .nav-pills {
  padding-bottom: 1.7em;
}

@include media-breakpoint-up(md) {
  .z-tabs.z-tabs-dashboard .nav-item .nav-link.active::before {
    bottom: -1.7em;
  }
}
</style>
