<template>
  <div
    v-tooltip.top="showTooltipMessage(type)"
    :class="{ disabled: !enabled }"
    class="service-type d-flex align-items-center"
    role="button"
  >
    <div>
      <service-type-avatar :type="type" />
    </div>
    <div class="name ml-3">
      {{ serviceName }}
    </div>
  </div>
</template>

<script>
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "ZServiceType",

  components: {
    ServiceTypeAvatar
  },

  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      required: true
    }
  },

  computed: {
    serviceName() {
      return this.serviceTypeCondition(
        "Entrega Express",
        "Entrega de Guías",
        ServiceType.RENT_PER_HOUR
      );
    }
  },

  methods: {
    showTooltipMessage(serviceType) {
      let serviceName = "";
      let message = `no es compatible con tu giro empresarial. Para habilitar activalo en Perfil.`;

      if (ServiceType.isMultiPoint(serviceType)) {
        serviceName = "Express";
      } else if (ServiceType.isParcel(serviceType)) {
        serviceName = "Guías";
      } else {
        serviceName = ServiceType.RENT_PER_HOUR;
      }

      return this.enabled ? "" : `${serviceName} ${message}`;
    },
    serviceTypeCondition(mpVal, pckVal, rVal) {
      switch (this.type) {
        case ServiceType.NUM_MULTI_POINT:
          return mpVal;
        case ServiceType.NUM_PARCEL:
          return pckVal;
        case ServiceType.NUM_RENT_PER_HOUR:
          return rVal;
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.service-type {
  padding: 0.6rem 0.8rem 0.6rem 0.6rem;
  background-color: var(--delivery-type-primary-bg);
  font-weight: 600;
  border-radius: 0.6em;

  .name {
    color: var(--primary);
  }

  &.disabled {
    background-color: $solitude;

    .name {
      color: $blue-haze;
    }
  }
}

@include media-breakpoint-up(md) {
  .service-type {
    font-size: 0.9rem;
  }
}
</style>
