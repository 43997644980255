<template>
  <div>
    <b-row class="mb-3">
      <b-col md="5" lg="3">
        <z-search-input
          v-model="searchText"
          placeholder="Buscar por ID Orden"
        />
      </b-col>
      <b-col v-if="isCompany" lg="2" class="mt-3 mt-lg-0">
        <z-dropdown-clients id="dropdown-clients" v-model="filterClient" />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-status"
          v-model="filterStatus"
          :options="statusOptions"
          name="Estatus"
        />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-status"
          v-model="filterDeliveryType"
          :options="deliveryTypeOptions"
          name="Entrega"
        />
      </b-col>
      <b-col class="mt-3 mt-lg-0 ">
        <div class="d-flex flex-row-reverse">
          <z-button-refresh
            :loading="loading"
            :has-updates="hasUpdates"
            @refresh="getListingAndResetPagination"
          />
        </div>
      </b-col>
    </b-row>
    <quick-message
      :title="quickMessage.title"
      :message="quickMessage.message"
      :show.sync="showQuickMessage"
      :type="quickMessage.type"
      with-icon
      class="my-2"
    />
    <z-table
      :items="items"
      :fields="fields"
      :loading="loading"
      empty-message="Sin entregas para hoy"
      @row-clicked="handleRowClick"
    >
      <!-- Headers -->
      <template v-slot:head(status)>
        <div>
          <div class="d-lg-none">Estatus</div>
          <z-table-header-dropdown
            id="dropdown-role-type"
            v-model="filterStatus"
            class="d-none d-lg-block"
            :options="statusOptions"
            name="Estatus"
          />
        </div>
      </template>

      <template v-slot:head(type)>
        <div>
          <div class="d-lg-none">
            Entrega
          </div>
          <z-table-header-dropdown
            id="dropdown-vehicle-type"
            v-model="filterDeliveryType"
            class="d-none d-lg-block"
            button-class="text-center"
            :options="deliveryTypeOptions"
            name="Entrega"
          />
        </div>
      </template>

      <template v-slot:head(createdAt)>
        <z-table-head-sort :order.sync="filterSortOrder">
          Fecha
        </z-table-head-sort>
      </template>

      <template v-if="isCompany" v-slot:head(clientName)>
        Sucursal
      </template>

      <!-- Cells -->
      <template v-slot:cell(status)="row">
        <status-delivery :status="row.item.status" :type="row.item.type" />
      </template>

      <template v-slot:cell(type)="row">
        <service-type-badge :type="row.item.type" />
      </template>

      <template v-slot:cell(id)="row">
        <span>
          {{ shortDeliveryId(row.item) }}
        </span>
      </template>

      <template v-if="isCompany" v-slot:cell(clientName)="row">
        <span v-if="isBranch(row.item)">
          {{ row.item.clientName }}
        </span>
      </template>

      <template v-slot:cell(vehicleType)="row">
        <z-vehicle
          v-if="row.item.vehicleType != null"
          :type="row.item.vehicleType"
        />
        <z-vehicle v-else :type="VehicleType.NUM_TRUCK" />
      </template>

      <template v-slot:cell(actions)="row">
        <div class="d-flex">
          <z-tracking-link
            :id="row.item.zubutId"
            :is-parcel="isParcel(row.item.type)"
            shorten-url
          />
          <z-actions-menu>
            <template v-slot:button-content>
              <z-icon name="More" />
            </template>
            <b-dropdown-item @click="handleCancel(row.item)">
              Cancelar entrega
            </b-dropdown-item>
          </z-actions-menu>
        </div>
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="totalItems"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import { formatISO } from "@zubut/common/src/utils/time";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge";
import ZTrackingLink from "@zubut/common/src/components/ZTrackingLink";
import ZVehicle from "@zubut/common/src/components/ZVehicle";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh";
import VehicleType from "@zubut/common/src/constants/vehicles/type";
import ZActionsMenu from "@zubut/common/src/components/ZActionsMenu";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ServiceType from "@zubut/common/src/constants/services/type";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import StatusDelivery from "@/app/components/StatusDelivery";
import ZDropdownClients from "@/app/components/ZDropdownClients";
import * as mutation from "@/store/modules/dashboard/deliveries/mutations-types";
import filtering from "@/mixins/filtering";
import _debounce from "lodash/debounce";
import DeliveriesFilterStatus from "@/constants/deliveries/filter-status";
import ParcelStatus from "@zubut/common/src/constants/parcels/status";
import ServiceStatus from "@zubut/common/src/constants/services/status";
import Paths from "@/constants/routes/paths";

const moduleName = "dashboard/deliveries";
const fieldClientNameIndex = 4;

export default {
  name: "DashboardDeliveries",

  components: {
    StatusDelivery,
    ServiceTypeBadge,
    ZActionsMenu,
    ZDropdown,
    ZDropdownClients,
    ZSearchInput,
    ZTable,
    ZTableHeaderDropdown,
    ZTableHeadSort,
    ZTablePagination,
    ZTrackingLink,
    ZVehicle,
    ZButtonRefresh
  },

  mixins: [filtering("dashboard")],

  data() {
    return {
      dateOrder: "desc",
      loading: false,
      VehicleType,
      deliveryTypeOptions: [
        { text: "Todos", value: null },
        { value: ServiceType.NUM_MULTI_POINT, text: "Express" },
        { value: ServiceType.NUM_PARCEL_PICKUP, text: "Guía" }
      ],
      statusOptions: [{ text: "Todos", value: null }].concat(
        DeliveriesFilterStatus.options
      ),
      showQuickMessage: false,
      quickMessage: {
        title: "Error",
        message: "",
        type: "error"
      }
    };
  },

  computed: {
    isCompany() {
      return this.$store.getters[`user/isCompany`];
    },
    items() {
      return this.$store.getters[`${moduleName}/getDeliveries`];
    },
    fields() {
      const fields = [
        { key: "status", label: "Estatus", class: "text-left" },
        { key: "type", label: "Entrega", class: "text-left" },
        {
          key: "zubutId",
          label: "ID Orden",
          class: "text-left",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          class: "text-left",
          formatter: val =>
            val ? formatISO(val, "dd 'de' MMM yyyy p aaa") : ""
        },
        { key: "deliverTo", label: "Entregar a", class: "text-left" },
        { key: "driver", label: "Mensajero", class: "text-left" },
        { key: "vehicleType", label: "", class: "text-left" },
        { key: "actions", label: "", class: "text-left" }
      ];
      if (this.isCompany) {
        fields.splice(fieldClientNameIndex, 0, {
          key: "clientName",
          label: "Sucursal",
          class: "text-left"
        });
      }

      return fields;
    },
    stateFilters() {
      return this.$store.getters[`${moduleName}/getDeliveriesFilters`];
    },
    filterClient: {
      get() {
        return this.stateFilters.clientId;
      },
      set(val) {
        this.setFilter("client", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_CLIENT}`, val);
      }
    },
    filterStatus: {
      get() {
        return this.stateFilters.status;
      },
      set(val) {
        this.setFilter("status", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_STATUS}`,
          val
        );
      }
    },
    filterDeliveryType: {
      get() {
        return this.stateFilters.deliveryType;
      },
      set(val) {
        this.setFilter("deliveryType", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_DELIVERY_TYPE}`,
          val
        );
      }
    },
    filterSortOrder: {
      get() {
        return this.stateFilters.sortOrder;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_ORDER}`,
          val
        );
      }
    },
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getDeliveriesFilterText`];
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_DELIVERIES_FILTER_TEXT}`,
          val
        );
      }
    },
    totalItems() {
      return this.$store.getters[`${moduleName}/getDeliveriesTotal`];
    },
    hasUpdates: {
      get() {
        return this.$store.getters[`${moduleName}/getHasUpdates`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.TOGGLE_UPDATE_INDICATOR}`,
          val
        );
      }
    }
  },

  watch: {
    dateOrder: "getListingAndResetPagination",
    filterSortOrder: "getListing",
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    }
  },

  created() {
    this.setRouteFilters();
    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getListing(), 100);
    this.searchFilterListener = _debounce(() => this.getListing(), 600);
    this.defaultListener();
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab;
      const search = this.searchText;
      const page = this.currentPage;
      const show = this.perPage;
      const client = this.filterClient;
      const deliveryType = this.filterDeliveryType;

      query.tab = query.tab ? query.tab : tab;
      query.client = query.client || !client ? query.client : client;
      query.search = query.search ? query.search : search;
      query.page = query.page ? query.page : page;
      query.show = query.show ? query.show : show;
      query.deliveryType = query.deliveryType
        ? query.deliveryType
        : deliveryType || query.deliveryType;

      this.searchText = query.search;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});
    },
    getListingAndResetPagination() {
      this.hasUpdates = false;
      this.paginationReset();
      this.getListing();
    },
    getListing() {
      if (this.$route.path === `/${Paths.DASHBOARD}`) {
        this.loading = true;
        const { filter, where } = this.buildRequest();
        this.$store
          .dispatch(`${moduleName}/getDeliveries`, {
            filter,
            where
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    buildRequest() {
      const filter = {
        ...this.pagination,
        order: `createdAt ${this.filterSortOrder}`
      };

      const where = {
        and: []
      };
      const expressFilter = {};
      const parcelFilter = {};

      if (this.searchText) {
        where.and.push({
          or: [
            { zubutId: { like: `%${this.searchText}%` } },
            { externalId: { like: `%${this.searchText}%` } }
          ]
        });
      }

      if (this.filterClient) {
        where.and.push({ clientId: this.filterClient });
      }

      /* Set service status filter */
      if (this.filterStatus !== null) {
        // Express & Parcel deliveries have multiple status but
        // on the dashboard we only want the ones that fit within
        // created & in progress status
        let orFilter = [];
        if (this.filterStatus === DeliveriesFilterStatus.NUM_CREATED) {
          expressFilter.status = { inq: ServiceStatus.createdStatus };
          parcelFilter.status = ParcelStatus.NUM_PENDING_COLLECTION;
        } else if (
          this.filterStatus === DeliveriesFilterStatus.NUM_IN_PROGRESS
        ) {
          expressFilter.status = ServiceStatus.NUM_ACTIVE;
          parcelFilter.status = { inq: ParcelStatus.inProgressStatus };
        }
        expressFilter.type = { inq: ServiceType.mulitPointTypes };
        parcelFilter.type = { inq: ServiceType.parcelTypes };

        orFilter.push(expressFilter, parcelFilter);
        where.and.push({ or: orFilter });
      } else if (this.filterDeliveryType !== null) {
        // Express & Parcel deliveries apply to multiple types
        let orFilter = [];
        if (ServiceType.isMultiPoint(this.filterDeliveryType)) {
          expressFilter.type = { inq: ServiceType.mulitPointTypes };
          orFilter.push(expressFilter);
        } else if (ServiceType.isParcel(this.filterDeliveryType)) {
          parcelFilter.type = { inq: ServiceType.parcelTypes };
          orFilter.push(parcelFilter);
        }

        where.and.push({ or: orFilter });
      }

      if (!where.and.length) {
        delete where.and;
      }

      return { filter, where };
    },
    shortDeliveryId(item) {
      const id = item.id || item.serviceId || item.parcelId;
      return makeShortUUID(id);
    },
    paginationReset() {
      this.currentPage = 1;
    },
    isParcel(type) {
      return (
        type === ServiceType.NUM_PARCEL_PICKUP ||
        type === ServiceType.NUM_PARCEL_DELIVERY
      );
    },
    handleRowClick(item) {
      this.$router.push({
        name: "deliveryDetail",
        params: { id: item.id },
        query: { type: item.type }
      });
    },
    handleCancel(item) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/cancel`, item)
        .then(res => {
          this.quickMessage = {
            title: "Éxito",
            type: "success",
            message: res.message || "Entrega cancelada existosamente"
          };
          this.getListingAndResetPagination();
        })
        .catch(err => {
          this.loading = false;
          this.quickMessage = {
            title: "Error",
            type: "error",
            message:
              err.message || "Ocurrió un error al intenter cancelar la entrega"
          };
        });
    },
    isBranch(item) {
      return item.clientId != this.$store.state.user.id;
    }
  }
};
</script>
