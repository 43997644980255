<template>
  <div
    class="z-button-refresh"
    :class="{ default: variant === 'default', small: variant === 'small' }"
  >
    <template v-if="variant === 'small'">
      <z-button
        :disabled="loading || disabled"
        @click="refresh"
        variant="secondary"
        fit="auto"
        class="h-100"
      >
        <font-awesome-icon
          icon="reload"
          size="lg"
          :class="{ loading }"
          :disabled="loading || disabled"
        />
        <span
          v-show="btnText"
          :class="['ml-1 text-sm', { loading, outdated: hasUpdates }]"
        >
          {{ btnText }}
        </span>
      </z-button>
    </template>
    <button
      v-else
      data-test-id="refresh-button"
      :disabled="loading || disabled"
      @click="refresh"
    >
      <font-awesome-icon id="icon" :class="{ loading }" icon="reload" />
      <span :class="{ loading, outdated: hasUpdates }">{{ btnText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ZButtonRefresh",

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Actualizar"
    },
    loadText: {
      type: String,
      default: "Actualizando"
    },
    hasUpdates: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "small"].indexOf(val) > -1
    }
  },

  computed: {
    btnText() {
      if (this.loading) return this.loadText;
      return this.text;
    }
  },

  methods: {
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>

<style lang="scss" scoped>
.outdated {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -9px;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    border: 1px solid $white;
    background: var(--primary);
  }
}

.z-button-refresh {
  span {
    display: inline;
  }

  .fa-reload {
    font-size: 18px;
  }

  .loading {
    color: $grey;
  }

  .fa-reload.loading {
    transition: transform 0.5s linear infinite;
    transform-origin: 50% 50%;
    animation: rotate 1s infinite linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }
}

.z-button-refresh.default button {
  width: 122px;
  padding: 0.8em 1em;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 16px;
  background-color: transparent;

  .fa-reload {
    margin-right: 8px;
  }
}

@media (min-width: 500px) {
  .z-button-refresh {
    span {
      display: none;
    }
  }
}

@media (min-width: 860px) {
  .z-button-refresh {
    span {
      display: inline;
    }
  }
}
</style>
