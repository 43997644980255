import { render, staticRenderFns } from "./ZShortenText.vue?vue&type=template&id=015ee6f4&scoped=true&"
import script from "./ZShortenText.vue?vue&type=script&lang=ts&"
export * from "./ZShortenText.vue?vue&type=script&lang=ts&"
import style0 from "./ZShortenText.vue?vue&type=style&index=0&id=015ee6f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015ee6f4",
  null
  
)

export default component.exports