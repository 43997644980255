const CREATED = "Creado";
const IN_PROGRESS = "En progreso";

const NUM_CREATED = 0;
const NUM_IN_PROGRESS = 1;

const get = {
  [NUM_CREATED]: CREATED,
  [NUM_IN_PROGRESS]: IN_PROGRESS
};

const options = [
  { value: NUM_CREATED, text: CREATED },
  { value: NUM_IN_PROGRESS, text: IN_PROGRESS }
];

export default {
  get,
  options,
  CREATED,
  IN_PROGRESS,
  NUM_CREATED,
  NUM_IN_PROGRESS
};
