<template>
  <z-dropdown
    search
    :options="branches"
    :loading="loading"
    :max-height="maxHeight"
    :name="name"
    :value="value"
    :variant="variant"
    :placeholder="placeholder"
    :search-placeholder="searchPlaceholder"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ClientTypes from "@zubut/common/src/constants/clients/type";
import _debounce from "lodash/debounce";

export default {
  name: "ZDropdownClients",

  components: {
    ZDropdown
  },

  props: {
    clientTypes: {
      type: [Array, Number],
      default: () => [
        ClientTypes.NUM_CLIENT,
        ClientTypes.NUM_COMPANY,
        ClientTypes.NUM_BRANCH
      ]
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: "Sucursales"
    },
    placeholder: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar sucursal"
    },
    value: {
      type: [Number, String],
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    },
    exclude: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    branches() {
      return this.$store.getters["branches/branchesOptions"];
    }
  },

  created() {
    this.getClientsOptions();
  },

  methods: {
    getClientsOptions() {
      this.loading = true;
      this.$store.dispatch("branches/getBranches").finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
