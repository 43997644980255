



























import Vue from "vue";
import Clipboard from "../utils/clipboard";

export default Vue.extend({
  name: "ZShortenText",

  props: {
    text: {
      type: String,
      required: true
    },
    shortText: {
      type: String,
      default: ""
    },
    limit: {
      type: Number,
      default: 20
    },
    dots: {
      type: Boolean,
      default: true
    },
    copy: {
      type: Boolean,
      default: false
    },
    showCopyOnHover: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: "top",
      validator: val => ["top", "bottom", "left", "right"].indexOf(val) > -1
    }
  },

  data() {
    return {
      showCopy: false
    };
  },

  computed: {
    parsedText(): string {
      if (this.shortText) return this.shortText;
      return this.text;
    }
  },

  watch: {
    copy: {
      immediate: true,
      handler() {
        if (this.showCopyOnHover) {
          this.showCopy = false;
        } else {
          this.showCopy = this.copy;
        }
      }
    }
  },

  methods: {
    handleCopy() {
      Clipboard.copy(this.text);
    },
    handleHover(hovering: boolean) {
      if (this.showCopyOnHover) {
        this.showCopy = hovering;
      }
    }
  }
});
