<template>
  <!-- TODO: Delete this (Guias and Guia multiple is going to be deprecated) -->
  <!-- this component is not longer used (there should be more for the guias and guia multiple) -->
  <z-modal
    centered
    size="s"
    :hide-close="true"
    :show="show"
    @update:show="hideModal"
  >
    <div class="container">
      <h3>Crear guías(s)</h3>

      <modal-option
        v-for="(option, index) in options"
        :key="option.page"
        :page="option.page"
        :title="option.title"
        :text="option.text"
        :is-active="option.isActive"
        @click.native="selectOption(index, option)"
      />

      <div class="buttons">
        <z-button variant="secondary" @click="hideModal"
          ><b>Cancelar</b></z-button
        >
        <z-button :disabled="disabled" @click="goToPage"
          ><b>Siguiente</b></z-button
        >
      </div>
    </div>
  </z-modal>
</template>

<script>
import ZButton from "@zubut/common/src/components/ZButton.vue";
import ModalOption from "./ModalOption.vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";

export default {
  name: "SelectionModal",
  components: { ModalOption, ZButton, ZModal },
  props: {
    show: { type: Boolean, required: true }
  },
  data() {
    return {
      options: this.createFreshOptions(),
      prevOption: null
    };
  },

  computed: {
    disabled() {
      return this.prevOption === null;
    },
    pagesDirectory() {
      const pagesDirectory = {
        guias: { name: "createPackageDelivery" },
        "guias-multiples": { name: "createMultiplePackagesDeliverys" }
      };
      return pagesDirectory;
    }
  },

  methods: {
    goToPage() {
      const route = this.pagesDirectory[this.prevOption.page];

      if (route) this.$router.push(route).catch(() => {});

      this.hideModal();
    },
    createFreshOptions() {
      return [
        {
          page: "guias",
          title: "Guía",
          text: "Crear una guía manualmente",
          isActive: false
        },
        {
          page: "guias-multiples",
          title: "Carga múltiple",
          text: "Importa un CSV con múltiples guías",
          isActive: false
        }
      ];
    },
    hideModal() {
      this.resetState();
      this.$emit("update:show", false);
    },
    resetState() {
      this.options = this.createFreshOptions();
      this.prevOption = null;
    },
    selectOption(index, currentOption) {
      const isNotEveryOptionActive = this.options.every(
        option => option.isActive === false
      );

      if (isNotEveryOptionActive) {
        this.options[index].isActive = true;
        this.prevOption = { index, page: currentOption.page };
      } else {
        if (currentOption.isActive) return;

        this.options[this.prevOption.index].isActive = !this.options[
          this.prevOption.index
        ].isActive;
        this.options[index].isActive = !currentOption.isActive;

        this.prevOption = { index, page: currentOption.page };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  padding-bottom: 24px;
  font-weight: 600;
}

.container {
  padding: 12px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
  & > * {
    width: 100%;
  }
  & > button:first-of-type {
    margin-bottom: 12px;
  }

  @media (min-width: 500px) {
    flex-wrap: nowrap;
    & > * {
      width: 100px;
    }
    & > button:first-of-type {
      margin: 0 12px 0 0;
    }
  }
}
</style>
