<template>
  <div class="option" :class="{ active: isActive }">
    <div>
      <service-type-avatar v-if="isAvatarComponent" :type="packageType" />
      <component :is="icon" v-else></component>
    </div>
    <div>
      <span>{{ title }}</span>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar.vue";
import ServiceType from "@zubut/common/src/constants/services/type";
export default {
  name: "ModalOption",
  components: { ServiceTypeAvatar },
  props: {
    page: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: String, required: true },
    isActive: { type: Boolean, default: false }
  },
  data() {
    return {
      packageType: ServiceType.NUM_PARCEL_DELIVERY,
      iconsDirectory: {
        "guias-multiples": () => import("@/assets/icons/ic_xls_color.svg")
      }
    };
  },
  computed: {
    icon() {
      return this.iconsDirectory[this.page];
    },
    isAvatarComponent() {
      return this.page === "guias";
    }
  }
};
</script>

<style lang="scss" scoped>
span {
  font-weight: 600;
}
p {
  margin: 0;
}
.option {
  display: flex;
  gap: 24px;
  align-items: center;
  background: $ghost-white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    background: $alice-blue;
  }
}
.active {
  background: $alice-blue;
  outline: 2px solid $jordy-blue;
}
</style>
