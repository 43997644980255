const MOTO = "Motocicleta";
const CAR = "Carro";
const ECO = "Bicicleta";

const NUM_MOTO = 0;
const NUM_CAR = 1;
const NUM_ECO = 2;

const get = {};
get[NUM_MOTO] = MOTO;
get[NUM_CAR] = CAR;
get[NUM_ECO] = ECO;

const options = [
  { value: NUM_MOTO, text: MOTO },
  { value: NUM_CAR, text: CAR },
  { value: NUM_ECO, text: ECO }
];

export default {
  get,
  options,
  NUM_MOTO,
  NUM_CAR,
  NUM_ECO
};
