<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import DeliveriesFilterStatus from "@/constants/deliveries/filter-status";
import ParcelStatus from "@zubut/common/src/constants/parcels/status";
import ServiceStatus from "@zubut/common/src/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "DeliveryStatus",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      if (ServiceType.isMultiPoint(this.type)) {
        return this.expressStatusName(this.status);
      }
      return this.parcelStatusName(this.status);
    },
    colorVariant() {
      return "green";
    }
  },
  methods: {
    expressStatusName(status) {
      if (ServiceStatus.createdStatus.indexOf(status) > -1) {
        return DeliveriesFilterStatus.CREATED;
      } else if (status === ServiceStatus.NUM_ACTIVE) {
        return DeliveriesFilterStatus.IN_PROGRESS;
      }
    },
    parcelStatusName(status) {
      if (status === ParcelStatus.NUM_PENDING_COLLECTION) {
        return DeliveriesFilterStatus.CREATED;
      } else if (ParcelStatus.inProgressStatus.indexOf(status) > -1) {
        return DeliveriesFilterStatus.IN_PROGRESS;
      }
    }
  }
};
</script>
